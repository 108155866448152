@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      @apply scroll-smooth;
    }
  }

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

::-webkit-scrollbar-track {
    border-radius: 100vh;
}

::-webkit-scrollbar-thumb {
    background: #0C0C54;
    border: 1px solid #0C0C54;
}

/* html{
    scroll-behavior: smooth !important;
} */

body{
    font-family: 'Clash Grotesk', sans-serif;
    font-weight: 500;
}

.font-inter{
    font-family: 'Inter', sans-serif;
}

.splide__pagination{
  bottom: -3px !important;
}
.splide__pagination__page{
  background: #D0E8F7 !important;
}
.splide__pagination__page.is-active{
  background: #148ED7 !important;
}

@media screen and (min-width: 1200px){
  .splide__pagination{
    bottom: 10px !important;
  }
}